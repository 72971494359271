const premiumVipRMSs = [150, 151];

export const isPremiumVip = (initData, gameConfig, eligibilityInfo) => {
    const isPremiumVipRms = premiumVipRMSs.includes(eligibilityInfo.checkEligibilityResponse.rmsGroup);
    return isPremiumVipRms;
};

export const isNormalVip = (initData, gameConfig, eligibilityInfo) => {
    const isVip = initData.userInfo.isVIP;
    const isPremiumVip = premiumVipRMSs.includes(eligibilityInfo.checkEligibilityResponse.rmsGroup);
    return isVip && !isPremiumVip;
};

export const isAnyVip = (initData, gameConfig, eligibilityInfo) => {
    return isPremiumVip(initData, gameConfig, eligibilityInfo) || isNormalVip(initData, gameConfig, eligibilityInfo);
};