import {isInItaly, ukRestriction} from "./conditions/isInCountry";
import {isFTD0} from "./conditions/isFTD0";
import {noDaily, noLucky} from "./actions/noWheel";
import {hasUnsupportedCurrency} from "./conditions/hasUnsupportedCurrency";
import {setCurrencyToDollar} from "./actions/setCurrencyToDollar";
import {isAnyVip} from "./conditions/isVip";

export const DecoratorMap = {
    "UK_FTD0_NO_LUCKY": {
        conditions: [ukRestriction, isFTD0],
        actions: [noLucky]
    },
    "RESTRICT_UNSUPPORTED_CURRENCIES": {
        conditions: [hasUnsupportedCurrency],
        actions: [setCurrencyToDollar]
    },
    "IT_VIP_NO_DAILY": {
        conditions: [isInItaly, isAnyVip],
        actions: [noDaily]
    }
}