import {DecoratorMap} from "../restrictions/DecoratorMap";

export const getRestrictions = (cfg) => {
    let ret = null;
    if (cfg.restrictions) {
        ret = {
            restrictions: []
        };
        cfg.restrictions.forEach(restrictionId => ret.restrictions.push(DecoratorMap[restrictionId]));
    }

    return ret;
}