export const noLucky = (proxy) => {
    noWheel(proxy, "luckyWheel");
}

export const noDaily = (proxy) => {
    noWheel(proxy, "dailyWheel");
}

const noWheel = (proxy, wheel) => {
    const wheelIndex = proxy.availableSkins.findIndex(element => element.includes(wheel));
    if (wheelIndex>=0) {
        proxy.availableSkins.splice(wheelIndex, 1);
    }
}