/**
 * We want to provide 2 paths here:
 * 1. launcherRoot - root for launcher project from where we can load launcher files
 * 2. gameBaseUrl - base url for the game
 *
 * Widget url will be of the form:
 * https://[[domain]]/[[insignificantPath_0]]/.../[[insignificantPath_n]]/f2p/launcher/index.js
 * Game would always be positioned in:
 * https://[[domain]]/[[insignificantPath_0]]/.../[[insignificantPath_n]]/f2p/[[gameFolder]]/
 * For gameBaseUrl - we want to go 2 up and then add the game url from config
 * For launcherRoot we want just 1 up, so we can load launcher files like resources/urls.json
 *
 * @param widgetUrl
 * @param f2pGameUrl
 */
export const getUrlComponents = (widgetUrl, f2pGameUrl) => {
    //stupid hack
    const urlParts:any = widgetUrl.split("/");
    urlParts.pop();
    const launcherRoot = urlParts.join("/");
    urlParts.pop();
    const gameBaseUrl = urlParts.join("/") + f2pGameUrl;

    /* webpack-strip-code:removed */

    return {
        launcherRoot: launcherRoot,
        gameBaseUrl: gameBaseUrl
    };
};