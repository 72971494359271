const isInGreatBritain = (initData, gameConfig, eligibilityInfo) => {
    return isInCountry(eligibilityInfo, "GBR");
}

const isInIreland = (initData, gameConfig, eligibilityInfo) => {
    return isInCountry(eligibilityInfo, "IRL");
}

const isInCountry = (eligibilityInfo, country) => {
    return eligibilityInfo.checkEligibilityResponse.country === country;
}

export const ukRestriction = (initData, gameConfig, eligibilityInfo) => {
    return isInGreatBritain(initData, gameConfig, eligibilityInfo) || isInIreland(initData, gameConfig, eligibilityInfo);
}

export const isInItaly = (initData, gameConfig, eligibilityInfo) => {
    return isInCountry(eligibilityInfo, "ITA");
}