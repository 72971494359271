import {AppIdentifiers, IDivHostedAppInitParameters} from "@sparkware/uc-sdk-core";
/* webpack-strip-code:removed */
import {ProdConfigApi} from "./configAPIs/prod";
import {getUrlComponents} from "./util/getUrlComponents";
import {loadGameSrc} from "./util/loadGameSrc";
import {showVersion} from "./util/showVersion";

export interface IF2PGameConfigAPI {
    init: (orbitData:IDivHostedAppInitParameters) => void;
    gameUrl: string|null;
    containerID: string|null;
    loadUrls: (urlsRoot:string, baseUrl:string) => void;
}

export type UrlsConfig = {
    backendURL: string;
    logstashURL: string;
    reportingEnvironment: string;
    baseUrl?: string; // can be missing because it is added to the config object later, from code
}

export type MappedGameConfig = {
    subBrandIDsOpeningThis: number[],
    url: string|{[key:string]: string;}; // second type is for dev only!
    skins?: {
        [key:string]: string;
    },
    config?: {
        [key:string]: {
            tAndCLinks?: {
                publicationId?: string;
                templateId?: string;
            }
        }
    }
}

export type MappedConfig = {
    [key:string]: MappedGameConfig
}

window[AppIdentifiers.CasinoBonusGameWidget] = {
    init: async (orbitData:IDivHostedAppInitParameters) => {
        const configApi:IF2PGameConfigAPI =
            /* webpack-strip-code:removed */
            new ProdConfigApi();
        configApi.init(orbitData);

        const paths = getUrlComponents(orbitData.applicationContext.widgetUrl, configApi.gameUrl);
        await showVersion(paths.launcherRoot);
        await loadGameSrc(paths.gameBaseUrl + "game.js", configApi.containerID, () => {
            configApi.loadUrls(paths.launcherRoot, paths.gameBaseUrl);
        });
    }
};